.drn-icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	width: 26px;
	height: 26px;
}

.drn-icon-xs {
    width: 14px;
    height: 14px;
}

.drn-icon-sm {
    width: 20px;
    height: 20px;
}

.drn-icon-md {
    
}

.drn-icon-lg {
    
}

.drn-icon-xl {
    width: 46px;
    height: 46px;
}

.drn-icon-xxl {
    
}

.drn-icon-inline {
	vertical-align: middle;
}

.drn-icon-rotated {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    mask-position: left !important;
}

.drn-icon-youtube {
	// background-image: url('/images/icons/site-default/youtube.svg');
}

.drn-icon-youtube-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/youtube.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/youtube.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-instagram {
	// background-image: url('/images/icons/site-default/instagram.svg');
}

.drn-icon-instagram-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/instagram.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/instagram.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-pinterest {
	// background-image: url('/images/icons/site-default/pinterest-p.svg');
}

.drn-icon-pinterest-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/pinterest-p.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/pinterest-p.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-tiktok {
	// background-image: url('/images/icons/site-default/tiktok.svg');
}

.drn-icon-tiktok-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/tiktok.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/tiktok.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-facebook {
	// background-image: url('/images/icons/site-default/facebook-f.svg');
}

.drn-icon-facebook-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/facebook-f.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/facebook-f.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-twitter {
	// background-image: url('/images/icons/site-default/twitter.svg');
}

.drn-icon-twitter-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/twitter.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/twitter.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-search {
    // background-image: url('/images/icons/site-default/search.svg');
}

.drn-icon-search-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/search.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/search.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-user {
	// background-image: url('/images/icons/site-default/user.svg');
}

.drn-icon-user-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/user.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/user.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-privacy {
    // background-image: url('/images/icons/site-default/privacy.svg');
}

.drn-icon-privacy-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/privacy.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/privacy.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-calculator {
    // background-image: url('/images/icons/site-default/calculator.svg');
}

.drn-icon-calculator-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/calculator.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/calculator.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-password {
    // background-image: url('/images/icons/site-default/password.svg');
}

.drn-icon-password-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/password.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/password.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-orders {
    // background-image: url('/images/icons/site-default/orders.svg');
}

.drn-icon-orders-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/orders.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/orders.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-moreoptions {
    // background-image: url('/images/icons/site-default/moreoptions.svg');
}

.drn-icon-moreoptions-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/moreoptions.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/moreoptions.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-address {
    // background-image: url('/images/icons/site-default/address.svg');
}

.drn-icon-address-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/address.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/address.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-images {
    // background-image: url('/images/icons/site-default/images.svg');
}

.drn-icon-images-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/images.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/images.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-badge {
    // background-image: url('/images/icons/site-default/badge.svg');
}

.drn-icon-badge-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/badge.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/badge.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-downloads {
    // background-image: url('/images/icons/site-default/downloads.svg');
}

.drn-icon-downloads-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/downloads.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/downloads.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-messagecenter {
    // background-image: url('/images/icons/site-default/messagecenter.svg');
}

.drn-icon-messagecenter-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/messagecenter.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/messagecenter.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-like {
	// background-image: url('/images/icons/site-default/like.svg');
}

.drn-icon-like-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/like.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/like.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-like-checked {
    // background-image: url('/images/icons/site-default/like-checked.svg');
}

.drn-icon-like-checked-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/like-checked.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/like-checked.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-logout {
    // background-image: url('/images/icons/site-default/logout.svg');
}

.drn-icon-logout-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/logout.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/logout.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-cart {
	// background-image: url('/images/icons/site-default/cart.svg');
}

.drn-icon-cart-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/cart.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/cart.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-trash {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-trash-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/trash.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/trash.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-archive {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-archive-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/archive.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/archive.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-un-archive {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-un-archive-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/un-archive.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/un-archive.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-edit {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-edit-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/edit.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/edit.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-view {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-view-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/view.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/view.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-digitalfile {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-digitalfile-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/digital-file.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/digital-file.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-help {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-help-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/help.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/help.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-levertijden {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-levertijden-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/levertijden.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/levertijden.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-invoice {
    // background-image: url('/images/icons/site-default/trash.svg');
}

.drn-icon-invoice-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/invoice.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/invoice.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-search {
	// background-image: url('/images/icons/site-default/search.svg');
}

.drn-icon-search-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/search.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/search.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-menu-arrow {
	// background-image: url('/images/icons/site-default/menu-arrow.svg');
}

.drn-icon-menu-arrow-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/menu-arrow.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/menu-arrow.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-mobile-menu {
	// background-image: url('/images/icons/site-default/mobile-menu.svg');
}

.drn-icon-mobile-menu-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/mobile-menu.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/mobile-menu.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-close {
	// background-image: url('/images/icons/site-default/close.svg');
}

.drn-icon-close-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/close.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/close.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-usp {
	// background-image: url('/images/icons/site-default/user.svg');
}

.drn-icon-usp-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/usp-check.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/usp-check.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-button-arrow {
	// background-image: url('/images/icons/site-default/button-arrow.svg');
}

.drn-icon-button-arrow-svg {
	background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/button-arrow.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/button-arrow.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-filter {
    // background-image: url('/images/icons/site-default/filter.svg');
}

.drn-icon-filter-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/filter.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/filter.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-anderformaat {
    // background-image: url('/images/icons/site-default/anderformaat.svg');
}

.drn-icon-anderformaat-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/anderformaat.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/anderformaat.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.drn-icon-duplicate {
    // background-image: url('/images/icons/site-default/duplicate.svg');
}

.drn-icon-duplicate-svg {
    background-color: currentColor;
    -webkit-mask: url('/images/icons/site-default/duplicate.svg') no-repeat 50% 50%;
    mask: url('/images/icons/site-default/duplicate.svg') no-repeat 50% 50%;
    mask-size: contain; 
    -webkit-mask-size: contain;
}

.usp-icon {
	margin-top: -0.3em;
	width: 18px !important;
    height: 18px !important;
}

.button-icon {
	position: absolute;
    width: 1.2em!important;
    height: 1.2em!important;
    top: 50%;
    right: 1em;
    transform: translateY(-45%);
}