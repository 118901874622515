.accordion {
	display: block;
	position: relative;
	width: 100%;
	margin-bottom: 1em;
}

.accordion-item {
	border: none;
	background-color: transparent;
}

.accordion-header {
	margin:0;
	border-bottom: 1px solid var(--primary-01);
}

.accordion-button {
	padding-top: 0.6rem;
    padding-right: 0;
    padding-bottom: 0.6rem;
    padding-left: 0;
	-webkit-border-radius: 0 0 0 0 !important;
	border-radius: 0 0 0 0 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: transparent;
}

.accordion-button:not(.collapsed) {
	color: inherit;
	background-color: transparent;
}

.accordion-button:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed):after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-body {
    padding-top: 1rem;
    padding-right: 0;
    padding-bottom: 0.5rem;
    padding-left: 0;
}